import type { Locale } from "date-fns";
import { da, enUS } from "date-fns/locale";

export const FULL_DATE_FORMAT = "PP p";
export const DATE_FORMAT = "PP";
export const INPUT_DATE_FORMAT = "yyyy-MM-dd";
export const INPUT_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const DEFAULT_PHONE_PREFIX = "+45";

export const LOCALE_STORAGE_KEY = "lang";

export const DATE_LOCALES: Record<string, Locale> = { en: enUS, da };
